@font-face {
  font-family: "architect";
  src: url(./fonts/Arquitecta/Arquitecta.woff);
}

html {
  background: #fef0eb;
}
.email_list {
  margin: 0 auto;
  list-style-type: none;
  max-width: 284px;
  padding-top: 10px;
}
.email_list li:before {
  content: "✓ ";
  margin-left: -8px;
  color: #f05523;
}
.calendar_black {
  margin-top: 2rem;
  text-align: center;
}
.calendar_black p {
  margin-top: 2rem;
}
.appointment_heading h1 {
  margin-top: 1rem;
  font-family: "architect" !important;
}
.appointment_heading h1 {
  font-size: 28px;
  text-align: center;
  font-family: "architect" !important;
}
.calendar_color_area {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0px 10px 60px 10px;
  border: 6px double #fff;
  margin-top: 20px;
  background: #f05523;
  border-radius: 10px;
}

.calendar_date_time {
  margin-left: 0.5rem;
  transform: translateY(35%);
}

.calendar_date_time p {
  color: #fff;
  font-weight: normal;
  font-size: 30px;
}
.calendar_date_time h2 span {
  color: #fff;
}

.note_main_area {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}
.note_area {
  display: flex;
  justify-content: center;

  max-width: 400px;
  width: 100%;
}
.note_area p {
  margin-left: 0.5rem;
  color: #666;
  font-size: 13px;
  font-family: arial;
  font-weight: normal;
}

.reg_btn_container {
  margin-top: 2rem;
}
.reg_btn {
  background-color: #f05523;
  border: none;
  border-radius: 50px;
  margin-top: 30px;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #fff;
  font-size: 22px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}
.reg_btn:hover {
  opacity: 0.8;
}
.app_circle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  width: 180px;
  border: 1px solid #b0b1af;
  border-radius: 80px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 250px;
  width: 100%;
  /* padding: 1.6rem 1rem; */
}

.app_circle:hover {
  cursor: pointer;
}
.form-check-input[type="radio"]:active,
.form-check-input[type="radio"]:focus-within,
.form-check-input:checked {
  background: #f05523;
  outline: none;
  box-shadow: none;
  border: 3px solid #fff;
  background-image: none !important;
}

.app_time {
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #b0b1af;
}
.app_btn_container {
  border-radius: 50%;
  /* border: 2px solid #b0b1af; */
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-check-input {
  height: 30px !important;
  width: 30px !important;
}
.app_btn {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #fff;
  border: none;
}
.selected_app_btn {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #f05523;
  border: none;
}
.app_circle:hover .app_btn {
  background-color: #f05523;
  border: none;
}
.app_circle:hover {
  background-color: #e5e4dd;
}
.app_circle:hover .app_time {
  color: black;
  font-weight: 400;
}
.react_calender_container {
  margin-top: 1rem;
  margin-bottom: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar {
  background: antiquewhite !important;
  border-radius: 15px !important;
  max-width: 500px !important;
  width: 100% !important;
  border: none !important;
}
.react-calendar button:enabled:hover {
  cursor: pointer !important;
  background: white !important;
  color: black !important;
  border-radius: 18px !important;
  height: 40px !important;
  /* width: 30px !important; */
  /* padding: 0; */
  /* padding: 0 !IMPORTANT; */
}
.react-calendar__tile--active {
  cursor: pointer !important;
  background: white !important;
  color: black !important;
  border-radius: 18px !important;
  height: 40px !important;
  /* width: 30px !important; */
  /* padding: 0; */
  /* padding: 0 !IMPORTANT; */
}
.react-calendar__tile--now {
  background: none !important;
}

.react-calendar__month-view__weekdays {
  color: #ff0000;
  text-decoration: none !important;
}

.react-datepicker-popper {
  position: unset !important;
  transform: unset !important;
  visibility: visible !important;
}
