/* ===== Loginscree Styles ==== */

.email_box {
    box-shadow: rgba(134, 133, 133, 0.35) 0px 5px 10px;
    background-color: #fff;
    border-radius: 16px;
    margin: 1rem 2rem;
    padding: 7rem 2rem;
}

.border{
    max-width: 60px;
}

.email-h1 {
    margin: 0;
    text-align: center;
    font-size: 40px;
}

.email-h1 div {
    margin: 0;
    text-align: center;
    font-size: 40px;
    color: #f05523;
}

.email_box
.email_p {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 45px;
    color: #000;
    font-size: 15px;
}

.confirm_p{
    text-align: center;
    margin-top: 30px;
    margin-bottom: 45px;
    color: red;
    font-size: 15px;
}

strong {
    color: #737072;
}

span {
    color: #f05523;
}

h6 {
    margin: 0px;
}

.put-email {
    display: flex;
    flex-direction: column;
}

.email_input {
    margin-left: 20px;
    color: #000;
    margin-top: 60px;
    font-weight: 500;
}

.email_btn {
    background-color: #f05523;
    border: none;
    border-radius: 50px;
    margin-top: 30px;
    padding-top: 14px;
    padding-bottom: 14px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
}

.email_btn:hover {
    opacity: 0.8;
    color: white;
  }

  .main_pad{
      display: flex !important;
  }

  @media (max-width: 400px ) {
     .email-h1 div {
         font-size: 26px;
     }
  }
