/* === Registration Styles === */
@font-face {
    font-family: 'architect';
    src: url(../../assets/fonts/Arquitecta/Arquitecta.woff);
}
.reg_box {
    box-shadow: rgba(134, 133, 133, 0.35) 0px 5px 10px;
    background-color: #fff;
    border-radius: 16px;
    margin: 1rem 2rem;
    padding: 1rem 2rem;
    height: 678px;
}
.reg_input {
    margin-left: 20px;
    color: #000;
    margin-top: 20px;
    font-weight: 500;
}

.reg_main_h1 {
    margin: 0px;
    font-size: 34px;
    text-align: center;
    font-family: 'architect';
    font-weight: bolder;
}
.reg_p {
    text-align: center;
    margin-top: 17px;
    margin-bottom: 17px;
    color: #000;
    font-size: 14px;
    font-family: 'architect';
    font-weight: 600;
}
