/* ===== Common Styles ==== */
@font-face {
    font-family: 'architect';
    src: url(../assets/fonts/Arquitecta/Arquitecta.woff);
}

.row{
    padding: 0 !important;
}
.logo {

}

.email_box{
    height: 678px !important;
}

.email_bg_img {
    width: 100%;
    height: auto;
}

.main_bg {
    background-color: #fef0eb;
}

.main_pad {
    padding: 4rem 0rem;
}

.dot_area {
    display: flex;
    margin-top: 15px;
    align-items: baseline;
}

.dot {
    height: 8px;
    width: 8px;
    background-color: #f05523;
    border-radius: 50%;
    display: inline-block;
}

.dot_p {
    margin-left: 10px;
}

.schedule-h1 {
    font-size: 28px;
    margin-top: 19px;
    font-family: 'architect';
    font-weight: bolder;
}

p {
    font-size: 18px;
    margin-bottom: 0px !important;
    margin-top: 5px;
    color: #737072;
    margin-left: 5px;
    font-family: 'architect';
    font-weight: 600;
}

.no-cost_text {
    margin-top: 25px;
}

.copyright_p {
    text-align: center;
    margin-top: 40px;
}

.field_area {
    border-radius: 35px;
    margin-top: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    border: 1px solid #f05523;
    border-width: 1px;
    padding-left: 16px;
    opacity: 0.6;
}

.check_mark_main {
    display: flex;
    justify-content: space-evenly;
    margin-top: 25px;
    padding-left: 30px;
    padding-right: 30px;
}

.schedule_box {
    box-shadow: rgba(134, 133, 133, 0.35) 0px 5px 10px;
    background-color: #fff;
    border-radius: 16px;
    margin: 1rem 2rem;
    padding: 3rem 2rem;
    padding-bottom: 70px;
    height: 678px;
}
.calender_area{
    visibility: visible !important;
    overflow-y: auto;
    height: 220px;
}


/*:::::::::::::::Chris Styles:::::::::::::::::::::*/
.hideme { display:none; }
.centerme { margin: 0 auto!important; }
.centerme .logo { position:relative; width: 130px; left: 50%; margin-left: -65px; }


@media screen and (max-width: 600px) {
  /*  .centerme { background-color: olive; } */
    .row { --bs-gutter-x: 0rem!important; }
    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl { padding: 0px!important; }
    .email_box, .reg_box, .schedule_box { margin: 5px!important; padding: 10px!important; }
  }
